import { useEffect, useRef } from 'react';
import {
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  DataZoomComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { SVGRenderer } from 'echarts/renderers';
import { t } from 'i18next';
import { formatNumberWithFractionDigits } from '~/helpers/thousandFormatNumber';
import { zoomTooltipOff } from '~/pages/Analysis/Integrations/IntegrHistory/ChillerCarrierHistory/components/ChillerParameters';
import moment from 'moment';
import { tooltipXLabelFormatter } from '~/helpers/historyGraph';

echarts.use([
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LineChart,
  SVGRenderer,
  UniversalTransition,
  DataZoomComponent,
]);

interface TooltipParams {
  name: string;
  value: number;
  seriesName: string;
  seriesType: string;
  dataIndex: number;
  axisValue: number;
  color: string;
}

export function GenerateGraphSample({
  state, graphEnable, axisInfo, hwCfg, manageAllClients,
}): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  const selectedParams = state.chartData.vars?.filter((varInfo) => graphEnable[varInfo.id]);

  const isDesktop = window.matchMedia('(min-width: 1039px)').matches;
  const isSmallMobile = window.matchMedia('(max-width: 550px)').matches;

  function formatterTooltip(value: TooltipParams[], unitMap: { [key: string]: string }) {
    const date = tooltipXLabelFormatter(value[0].axisValue, state.dateStart);
    const values: string[] = [];
    const tooltipContentFactory = [date];

    if (value.every((x) => x?.value == null)) return '';

    for (const item of value) {
      if (item.value == null) {
        continue;
      }
      const itemValue = verifyValue(item.value, item.seriesName);
      const itemName = item.seriesName?.replace('[Bar]', '').replace('[ΔTºC]', '[ΔT]');
      const cssObject = `<span style="color: ${item.color}">${itemName}: </span><span style="color: 'black'">${itemValue} ${unitMap[item.seriesName]}</span>`;
      values.push(cssObject);
    }

    tooltipContentFactory.push(...values);

    return tooltipContentFactory.join('<br />');
  }

  function tickXLabelFormaterDay(hour: number) {
    const numDays = Math.floor(hour / 24);
    const date = new Date(`${moment(state.dateStart).add(numDays + 1, 'days').format('YYYY-MM-DD')}T00:00:00Z`);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');

    const dateFinal = `${dd}/${mm}`;
    return `${dateFinal}${tickXLabelFormaterHour(hour)}`;
  }

  function tickXLabelFormaterHour(hour: number) {
    const numDays = Math.floor(hour / 24);
    const sign = hour - 24 * numDays < 0 ? '-' : '';
    const hh = Math.floor(Math.abs(hour)) - 24 * numDays;

    return `${'\n'} ${sign}${String(hh).padStart(2, '0')}:00`;
  }

  function verifyIndexY(axisId: string) {
    if (axisId === 'press') return 1;

    return 0;
  }

  function mapToFurthestMultiple(value: number) {
    const mappings = {
      '-3': -2.4,
      '-6': -4.5,
      '-9': -7.4,
      '-12': -9.5,
      '-15': -12.4,
      '-18': -15.5,
    };

    return mappings.hasOwnProperty(value.toString()) ? mappings[value.toString()] : value;
  }

  function getCustomTickTempLabel(params: {
    value: number, L1start: number, isFancoil: boolean, manageAllClients: boolean,
  }) {
    const {
      value, L1start, isFancoil, manageAllClients,
    } = params;

    let label = value.toString();

    const valAux = mapToFurthestMultiple(value);

    if (valAux < (L1start - 5)) {
      if (isFancoil && manageAllClients && (valAux < (L1start - 7) && valAux > (L1start - 10))) {
        label = (valAux % 5 > -2.5) ? t('ligadoAbreviado') : t('desligadoAbreviado');
      } else {
        label = (valAux % 5 > -2.5) ? t('liberadoAbreviado') : t('bloqueadoAbreviado');
      }
    } else if (valAux < L1start) {
      label = (valAux % 5 > -2.5) ? t('ligadoAbreviado') : t('desligadoAbreviado');
    }

    return label;
  }

  function verifyValue(value: number, paramName: string) {
    if (!state.chartData.vars || state.axisInfo.L1start === null) return '';

    const varInfo = state.chartData.vars.find((data) => data.name === paramName);
    const id = varInfo?.id;

    const isPressure = id === 'Psuc';
    const isBool = ['Levp', 'Lcut', 'Lcmp', 'L1raw', 'L1fancoil'].includes(id || '');

    let label = formatNumberWithFractionDigits(
      isPressure ? value.toFixed(1).toString() : value.toString(),
    );

    if (isBool) {
      const valAux = mapToFurthestMultiple(value);
      label = getBooleanLabel(valAux);
    }

    return label;
  }

  function getLabelBasedOnCondition(valAux: number, ifOption: string, elseOption: string): string {
    return valAux % 5 > -2.5 ? ifOption : elseOption;
  }

  function getBooleanLabel(valAux: number): string {
    const { L1start } = axisInfo;

    if (valAux < L1start - 5) {
      const condition = hwCfg.isFanCoil && manageAllClients && valAux < L1start - 7 && valAux > L1start - 10;

      if (condition) {
        return getLabelBasedOnCondition(valAux, t('ligadoAbreviado'), t('desligadoAbreviado'));
      }
      return getLabelBasedOnCondition(valAux, t('liberadoMin'), t('bloqueadoMin'));
    }

    if (valAux < L1start) {
      return getLabelBasedOnCondition(valAux, t('ligadoMin'), t('desligadoMin'));
    }

    return '';
  }

  function verifyYLabel() {
    return ((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && (state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2)) ? `${t('temperaturaAbreviado')}/${t('correnteAbreviado')}/${t('velocidadeAbreviado')}` : t('temperatura');
  }

  function renderToolbox() {
    return {
      toolbox: {
        feature: {
          dataZoom: {
            xAxisIndex: [0],
            title: {
              zoom: t('Zoom'),
              back: t('voltarAmpliacao'),
            },
            filterMode: 'filter',
          },
          restore: {
            title: t('restaurarZoom'),
          },
          saveAsImage: {
            title: t('salvarComoImagem'),
          },
        },
        top: '0%',
      },
    };
  }

  function verifyInterval() {
    const interval = Math.max(60, 1440 / state.numDays);

    if (isSmallMobile) return interval * 2;

    return interval;
  }

  useEffect(() => {
    const seriesData = state.chartData.vars?.filter((varInfo) => graphEnable[varInfo.id])?.map((item) => (
      {
        name: item.name,
        color: item.color,
        type: 'line',
        stack: item.name,
        smooth: true,
        data: item.y,
        lineStyle: { type: item.strokeDasharray ? 'dashed' : 'solid', width: 1 },
        showSymbol: false,
        yAxisIndex: verifyIndexY(item.axisId),
      }
    ));

    const unitMap: { [key: string]: string } = {};
    state.chartData.vars?.forEach((item) => {
      if (graphEnable[item.id]) {
        unitMap[item.name] = item.unit || '';
      }
    });
    const chartInstance = echarts.init(chartRef.current);

    const options = {
      legend: {
        data: selectedParams?.map((param) => param.name),
        bottom: 0,
        left: 'center',
        textStyle: {
          fontSize: isSmallMobile ? 8 : 10,
          color: '#333',
        },
        orient: 'horizontal',
        width: '100%',
        itemGap: 10,
        formatter: (name) => {
          const unitMed = unitMap[name];
          const unitAux = unitMed?.length ? `[${unitMed}]` : '';
          return `${name.replace('[Bar]', '').replace('[ΔTºC]', '').trim()} ${unitAux}`;
        },
      },
      grid: {
        top: '14%',
        bottom: isDesktop ? '15%' : '20%',
      },
      tooltip: {
        trigger: 'axis',
        formatter: (value) => formatterTooltip(value, unitMap),
        padding: 20,
        confine: true,
      },
      xAxis: {
        type: 'category',
        data: state.chartData.x,
        boundaryGap: false,

        axisLabel: {
          show: true,
          interval: verifyInterval(),
          formatter: (value) => {
            if (state.numDays === 1) {
              return tickXLabelFormaterHour(value);
            }
            return tickXLabelFormaterDay(value);
          },
          textStyle: {
            fontSize: 10,
          },
        },
        axisLine: { show: false },
        axisTick: { show: false },
      },
      yAxis: [
        {
          type: 'value',
          name: verifyYLabel(),
          min: Math.min(...axisInfo.tempTicks),
          max: Math.max(...axisInfo.tempTicks),
          id: 'temp',
          interval: 3,
          offset: 8,
          nameTextStyle: {
            padding: [0, 0, 0, 50],
          },
          axisLabel: {
            formatter: (value) => getCustomTickTempLabel({
              value,
              L1start: axisInfo.L1start,
              isFancoil: hwCfg.isFanCoil,
              manageAllClients,
            }),
            align: 'center',
            textStyle: {
              fontSize: 10,
            },
          },
        },
        {
          type: 'value',
          name: t('pressao'),
          min: axisInfo.presLimits[0],
          max: axisInfo.presLimits[1],
          position: 'right',
          id: 'press',
          axisLabel: {
            textStyle: {
              fontSize: 10,
            },
          },
          interval: 3,
        },
      ],
      series: seriesData,
      ...renderToolbox(),
    };

    chartInstance.setOption(options);

    zoomTooltipOff(chartInstance, '');

    const handleResize = () => chartInstance.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstance.dispose();
    };
  }, [state.chartData.vars, graphEnable, axisInfo]);

  return (
    <div ref={chartRef} style={{ width: '100%', height: '500px' }} />
  );
}
